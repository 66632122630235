import '../components/InfoBlocks.css';
import './ProjectPage.css'
import { Link } from 'react-router-dom';
import projects from '../projects.json'
import { TitleBlock } from '../components/InfoBlocks';
import { Helmet } from 'react-helmet';


function ProjectPage() {
  var currentProjects = []
  var pastProjects = []
  projects.forEach((item, index) => {
    // Grab target array by ref and add the project to the list.
    var targetArray = (item.isCurrent) ? currentProjects : pastProjects;
    targetArray.push(
      <div className='info-block project-block'>
        <h4 className="title-in-block">{item.title}</h4>
        <Link to={"/project/" + item.url}>
          <div className="max-width flex-container">
            <img className="project-image" key={index} alt={item.description} src={require("../" + item.image)} />
          </div>
        </Link>
        <h4>{item.description}</h4>
      </div>
    )
  }) 

  return (
    <div className="page-parent">
     <Helmet>
        <title>Larry Milne</title>
      </Helmet>
      <TitleBlock title="Current Projects"/>
      <div className="project-block-container">
        {currentProjects}
      </div>
      <TitleBlock title="Past Projects"/>
      <div className="project-block-container">
        {pastProjects}
      </div>
    </div>
  );
}

export default ProjectPage;