import {AboutBlock, MusicBlock, ProjectBlock, ProfileBlock } from '../components/InfoBlocks';
import '../components/InfoBlocks.css';

function HomePage() {
  return (
    <div className="page-parent">
      <ProfileBlock/>
      <div className="inline">
        <AboutBlock text={
          `
          My name is Lawrence Milne or as most people know me Larry or qawse3dr. 
          I graduated in computer science from the University of Guelph in 2023 and now
          reside as a software developer at BlackBerry where I work on the BlackBerry IVY project
          I have been programming since I was 14 years old, and it has become one of my greatest passions.
          I am mainly interested in systems technology, and working on lower-level technologies.
          Some other passions of mine include technology, Linux, playing and writing music with my guitar and
          bass, as well as playing video games and Magic The Gathering with my friends in my spare time.
          This is a collection of some of my random projects, work experience, and anything else I find interesting.
          `
        }/>
        <ProjectBlock/>
        <MusicBlock/>
      </div>
    </div>
  );
}

export default HomePage;