import "./InfoBlocks.css"
import 'react-slideshow-image/dist/styles.css'
import { Slide } from 'react-slideshow-image';


import profile from '../res/profile.png'
import projects from '../projects.json';

import { Link } from "react-router-dom";

function TitleBlock({title, className, style}) {
  return (
    <div className={"info-block title-block info-fit-width" + className} style={style}>
      <h1>{title}</h1>
    </div>
  );
}

function ProfileBlock() {
  return (
    <div>
      <TitleBlock title="Larry Milne"/>
      <div className="info-block profile-block info-fit-width">
        <img src={profile} alt="Profile"/>
        <h4>A.K.A qawse3dr</h4>
        <p>
          <h6>GitLab: <a href="https://gitlab.larrycloud.ca/qawse3dr">@qawse3dr</a> GitHub: <a href="https://github.com/qawse3dr">@qawse3dr</a></h6>
          <h6>Linkedin: <a href="https://www.linkedin.com/in/larry-milne-90469a1b5/">@Larry Milne</a></h6>
        </p>
      </div>
    </div>
  )
}

function AboutBlock({text}) {
  return (
    <div className="inline-child">
      <div className="info-block about-me-block">
        <h4 className="title-in-block">About</h4>
        <div className="scroll-block">
          <p> {text} </p>
        </div>
      </div>
    </div>
  )
}

function ProjectBlock() {
  return (
    <div className="inline-child">
      <div className="info-block" style={{textAlign: "center"}}>
        <h3 className="title-in-block"> Software Projects</h3>
        <div className="slide-container">
          <Slide transitionDuration={100}>
            {projects.map((item, index)=> ( 
                <div style={{width: "80%", margin: "auto"}}>
                  <h4 className="title-in-block">{item.title}</h4>
                  <Link to={"/project/" + item.url}>
                    <img style={{maxWidth: "80%", maxHeight: "10rem"}} alt={item.description} key={index}  src={require("../" + item.image)} />
                  </Link>
                </div>
              ))} 
          </Slide>
        </div>
      </div>
    </div>
  )
}

function Song({name, src}) {
  return (
    <div className="song">
      <h5>{name}</h5>
      <audio controls>
        <source src={src} type="audio/ogg"/>
      </audio>
    </div>
  )
}

function MusicBlock() {
  return (
    <div className="inline-child">
      <div className="info-block">
        <h4 className="title-in-block">Music</h4>
        <Song name="Flowers" src={require("../res/music/flowers.ogg")}/>
        <Song name="The Day Everything Changed" src={require("../res/music/TheDayEverythingChanged.mp3")}/>
      </div>
    </div>
  )
}


export {AboutBlock, MusicBlock, ProjectBlock, TitleBlock, ProfileBlock};