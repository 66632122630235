import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import { BrowserRouter, Routes, Route, ScrollRestoration } from 'react-router-dom';
import HomePage from './pages/HomePage';

import NoPage from './pages/NoPage';
import ProjectPage from './pages/ProjectPage';
import projects from './projects.json';
import ProjectTemplatePage from './pages/projects/ProjectTemplatePage';
import NavBar from './components/NavBar';
import ExperiencePage from './pages/Experience';
import GameTemplatePage from './pages/games/GamePage';



const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <BrowserRouter>
    <NavBar/>
    <Routes>
      <Route path="/">
        <Route index element={<HomePage/>}/>
        <Route path="project" element={<ProjectPage/>}/>
        {
          projects.map((p, i) => {
            return <Route path={"project/" + p.url} element={<ProjectTemplatePage project={p}/>}/>
          })
        }
        <Route path="experience" element={<ExperiencePage/>}/>
        <Route path="games/beeverse" element={<GameTemplatePage game={{name: "Beeverse", src: "https://beeverse.larrycloud.ca"}}/>}/>
        <Route path="*" element={<NoPage/>}/>
      </Route>
    </Routes>
    </BrowserRouter>
    
  </React.StrictMode>
);

