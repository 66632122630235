import './Projects.css';
import "../../components/InfoBlocks.css";

import { ProjectHeader, ProjectPictures, ProjectDownloads, ProjectTechnologies, asString } from '../../components/Project';
import { AboutBlock } from '../../components/InfoBlocks';

function ProjectTemplatePage({project}) {
  return (
    <div className="page-parent">
      <ProjectHeader project={project}/>
      <div className="inline">
        <AboutBlock text={asString(project.about)}/>
        <ProjectTechnologies tech={project.tech}/>
        {(project.downloads) ? <ProjectDownloads downloads={project.downloads}/> : null}
      </div>
        <ProjectPictures pictureInfo={project.screenShots}/>
    </div>
    
  );
}

export default ProjectTemplatePage;
