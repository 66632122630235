import "../../components/InfoBlocks.css";

import {TitleBlock } from '../../components/InfoBlocks';

function GameTemplatePage({game}) {
  return (
    <div className="page-parent">
      <TitleBlock title={game.name}/>
      <div className="info-block" style={{display: "flex", maxWidth: "1300px"}}>
        <iframe src={game.src} width="1280px" height="720px" style={{maxWidth: "100%", flex: "1"}}/>
      </div>
    </div>
  );
}

export default GameTemplatePage;
