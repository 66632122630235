import "../components/InfoBlocks.css";
import "./Experience.css"

import { TitleBlock } from "../components/InfoBlocks";

function ExperiencePage() {
  return (
    <div className="page-parent">
      <TitleBlock title="Current Job" />
      <div className="info-block experience-block">
          <h4 className="title-in-block">System Programmer (Software Developer 1)</h4>
          <h4 className="title-in-block">BlackBerry Waterloo In Office | May 2023 - Present</h4>
            <ul style={{textAlign: "left"}}>
              <li>Work on the BlackBerry IVY automotive project as a developer.</li>
              <li>SME and maintainer for C++ mock data player. Which has the ability to play data in the system allowing for options such as changing playback speed, playing multiple data files, as well as streaming large data files instead of loading the whole file into memory.</li>
              <li>Maintainer of BlackBerry IVY Data connector Samples. Make sure they stay up to date as well as acting as the gate keep for any changes in the repository.</li>
              <li>Developer Lead for 3+ Technical investigations. Responsible for leading the meetings as well as taking minutes and recording the required actions, as well as results. Responsible for taking product requirements and create a proposal with the feedback from architecture, as well as implementing the final solution, and documenting all user guides.</li>
              <li>Help develop host side developer tooling in Python with a fellow developer. The tooling allow creation and management of test targets, and interacting with the for the use of our platform.</li>
              <li>Worked to support all features on multiple target platforms including Linux and QNX</li>
            </ul>
      </div>
      <TitleBlock title="Coops"/>
      <div className="max-width experience-flex-container">
        <div className="info-block experience-block">
          <h4 className="title-in-block">Software Development Student</h4>
          <h4 className="title-in-block">BlackBerry Waterloo In Office | Jan 2022 - Aug 2022</h4>
            <ul style={{textAlign: "left"}}>
              <li>Worked on the BlackBerry IVY automotive project.</li>
              <li>Contributed to a C++ data ingestion framework, utilizing the CANbus to receive real world car data and ingest it into our platform</li>
              <li>Contributed to a C++ mock data player, Adding features such, pausing and resuming data playback, and changing the playback speed of data</li>
              <li>Responsible for implementing and unit testing feature requests, fixing defects, and working with feedback from our test team to design and create two TUI test applications using NCURSES, to simplifying and improving our testing. This allowed dynamic configurations of the applications as well as had separate virtual windows for data, and logs to make it easier to parse. This was done using NCURSES so that it could be used when testing embedded systems that doesn't have a display.</li>
              <li>Designed, created and documented a sample ingestor library for customers to reference, modify, and use as an example when working with our framework.</li>
              <li>Worked to support all features on multiple target platforms including Linux and QNX</li>
            </ul>
        </div>
        <div className="info-block experience-block">
          <h4 className="title-in-block">Software Development Student</h4>
          <h4 className="title-in-block">BlackBerry Waterloo Remote | Summer 2021</h4>
            <ul style={{textAlign: "left"}}>
              <li>Helped create a performance-focused C++ application to consume and organize data into more useful insights allowing for better decisions to be made by future developers.</li>
              <li>Conducted research into different technologies such as eBPF and how our team could leverage them; presented findings during sprint review.</li>
            </ul>
        </div>
        <div className="info-block experience-block">
          <h4 className="title-in-block">Software Development Student</h4>
          <h4 className="title-in-block">NCR Waterloo Remote | Fall 2020</h4>
            <ul style={{textAlign: "left"}}>
              <li>Worked with React front-end and Java back-end to work on an ATM application.</li>
              <li>Worked with a client to document, setup, and support TLS between our application and an Oracle DB to increase the security of their deployment of our application.</li>
            </ul>
        </div>
        <div className="info-block experience-block">
          <h4 className="title-in-block">Software Test Development Student</h4>
          <h4 className="title-in-block">BlackBerry Waterloo Remote | Fall 2020</h4>
            <ul style={{textAlign: "left"}}>
              <li>Worked with React front-end and Java back-end to work on an ATM application.</li>
              <li>Worked with a client to document, setup, and support TLS between our application and an Oracle DB to increase the security of their deployment of our application.</li>
            </ul>
        </div>
      </div>
    </div>
  );
}

export default ExperiencePage;
