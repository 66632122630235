import {Link} from 'react-router-dom';
import './NavBar.css'
import icon from '../res/profile.png'

function NavBar(){
  return (
  <div className='nav-bar'>
    <div style={{position: "fixed"}}></div>
    <Link to='/'>
      <img src={icon} className='nav-bar-element home-image'/>
    </Link>
    <div className='nav-bar-element'>
      <h3>Larrycloud</h3>
    </div>
    <div className='nav-buttons'>
      <Link to='/' className='nav-button'>
        <div className='nav-bar-element'><h3>Home</h3></div>
      </Link>
      <Link to='/project' className='nav-button'>
        <div className='nav-bar-element'><h3>Projects</h3></div>
      </Link>
      <Link to='/experience' className='na-button'>
        <div className='nav-bar-element'><h3>Experience</h3></div>
      </Link>
    </div>
  </div>
  );
}
export default NavBar;