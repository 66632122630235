import { Helmet } from "react-helmet"
import "../components/InfoBlocks.css"
import { TitleBlock } from "../components/InfoBlocks";

const NoPage = () => {
  return <div className="page-parent">
      <Helmet>
          <title>Larry Milne - 404</title>
      </Helmet>
      <TitleBlock title="Page Couldn't be found " style={{textAlign: "center", maxWidth: "80%" }} />
      <div className="info-block" style={{textAlign: "center", maxWidth: "80%" }}>
        <img style={{width: "15rem", margin: "auto", textAlign: "center", imageRendering: "pixelated"}} src={require("../res/LarrysAngelWarfare/player.png")} alt="404"/>
        <p>
            If you believe this page should exist try saying a prayer to Saint Anthony and reloading the page.
        </p>
      </div>
    </div>

};

export default NoPage;