import { Helmet } from "react-helmet";
import { TitleBlock } from "./InfoBlocks";
import { Link } from "react-router-dom";
import "./Project.css"
import "./InfoBlocks.css"
import { Slide } from "react-slideshow-image";


function ProjectHeader({project}) {
  return (
    <div>
      <Helmet>
        <title>{project.title}</title>
      </Helmet>
      <TitleBlock title={project.title}/>
      <div className="info-block project-header info-fit-width">
        {
          (project.video) ? <video controls src={require("../" + project.video)} alt={asString(project.description)}/> : <img src={require("../" + project.image)} alt={asString(project.description)}/> 
        }
        <div style={{display: "flex", textAlign: "center"}}>
          <a href={project.gitlab} style={{flex: 1}}>Gitlab</a>
          {(project.github) ? <a href={project.github} style={{flex: 1}}>Github</a> : null}
        </div>

      </div>
    </div>
  );
}

function ProjectDownloads({downloads}) {
  return (
    <div className="inline-child">
      <div className="info-block">
        <h4 className="title-in-block">Downloads and Links</h4>
        <ul>
          {
            downloads.map((download, i) => {
              return (download.file.startsWith("res")) ? <li><a href={require("../" + download.file)}>{download.name}</a></li> : <li><a href={download.file}>{download.name}</a></li>
            })
          }
        </ul>
      </div>
    </div>
  );
}

function ProjectTechnologies({tech}) {
  return (
    <div className="inline-child">
      <div className="info-block">
        <h4 className="title-in-block">Tech used</h4>
        <ul>
          {
            tech.map(function(t, i) {
              return <li>{t}</li>
            })
          }
        </ul>
      </div>
    </div>
    );
}

function ProjectPictures({pictureInfo}) {
  return (
    <div className="info-block project-block" style={{textAlign: "center", maxWidth: "80%", margin: "2rem auto"}}>
      <h3 className="title-in-block"> Screenshots</h3>
      <div className="slide-container">
        <Slide autoplay={false} duration={0} transitionDuration={0}>
          {pictureInfo.map((item, index)=> ( 
              <div style={{margin: "auto"}}>
                <h4 className="title-in-block">{item.title}</h4>
                <img style={{maxWidth: "80%",  maxHeight:"20rem", margin: "auto"}} key={index}  src={require("../" + item.img)} />
                <p style={{margin: "1rem 5%"}}>{asString(item.description)}</p>
              </div>
            ))} 
        </Slide>
      </div>
    </div>
  )
}

// Simple function to take a str or array of strings and return a resulting string
function asString(strOrArray) {
  return (Array.isArray(strOrArray)) ? strOrArray.join(" ") : strOrArray;
}

export {ProjectHeader, ProjectPictures, ProjectDownloads, ProjectTechnologies, asString}
